import React from 'react';
import './App.css';
import logo from './Assets/logo.webp';
import ImageSlider from './components/ImageSlider';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Deck Cariri</h1>
        <p className="description">
          Dá uma olhadinha nessas fotos aqui, isso é só algumas das várias delícias que temos aqui. Culinária diferenciada, ambiente fora do padrão e atendimento VIP.
        </p>
        <p className="description">
          Perde tempo não, toque no botão e peça agora ou venha nos visitar!
        </p>
        <a href="https://s.tintim.app/whatsapp/1997cbb3-36b4-4815-888f-aab29e7caf82/8e56be84-06f9-4fca-b89d-dd6c6e985e8c" target="_blank" rel="noopener noreferrer">
          <button>Pedir Agora</button>
        </a>
        <ImageSlider />
        <a href="https://s.tintim.app/whatsapp/1997cbb3-36b4-4815-888f-aab29e7caf82/8e56be84-06f9-4fca-b89d-dd6c6e985e8c" target="_blank" rel="noopener noreferrer">
          <button className="second-button">Pedir Agora</button>
        </a>
        <p className="contact-info">
          Praça Dionísio Rocha de Lucena, 280-A, Brejo Santo.<br />
          08:00 AM às 12:00 PM, Café da Manhã |🥗Almoço |🍝Jantar
        </p>
      </header>
    </div>
  );
}

export default App;
