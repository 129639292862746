import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../components/ImageSlider.css';
import image5 from '../Assets/image5.webp';
import image6 from '../Assets/image6.webp';
import image7 from '../Assets/image7.webp';
import image8 from '../Assets/image8.webp';
import image9 from '../Assets/image9.webp';
import image10 from '../Assets/image10.webp';
import image11 from '../Assets/image11.webp';
import image12 from '../Assets/image12.webp';
import image13 from '../Assets/image13.webp';

const images = [
  { src: image5, alt: 'Image 5' },
  { src: image6, alt: 'Image 6' },
  { src: image7, alt: 'Image 7' },
  { src: image8, alt: 'Image 8' },
  { src: image9, alt: 'Image 9' },
  { src: image10, alt: 'Image 10' },
  { src: image11, alt: 'Image 11' },
  { src: image12, alt: 'Image 12' },
  { src: image13, alt: 'Image 13' }
];

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Habilitar a reprodução automática
    autoplaySpeed: 1000 // Definir a velocidade da reprodução automática (1 segundo)
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="image-item">
            <img src={image.src} alt={image.alt} width="600" height="400" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;
